import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import ProductList from "./ProductList";
import Cropper from "../Cropper";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { customStyles } from "../../assets/css/CustomStyles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddProduct = (props) => {
  // Quill helpers start
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  // Quill helpers end

  const notiRef = useRef();
  const [taxlist, setTaxlist] = useState();

  const [data, setData] = useState({
    categoryId: "",
    categoryName: "",
    tax: "",
    image: "",
    productName: "",
    productDescription: "",
    moreInfo: "",
    productSpecification: [],
    tags: [],
  });

  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function handleChanges(e) {
    if (e.label) {
      console.log("files", e);
      setData({ ...data, productFormula: e.value });
    } else setData({ ...data, [e.target.name]: e.target.value });
  }

  const [catelist, setCateList] = useState();
  const [valueList, setValueList] = useState([]);
  const [render, setRender] = useState(false);
  const [submitRender, setSubmitRender] = useState(false);

  function notify(msg, type) {
    var options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
  }

  // Get Tax
  useEffect(() => {
    const getTax = () => {
      api
        .post("/admin/shop/tax")
        .then((res) => {
          if (res.data.data != "No Taxes found") {
            setTaxlist(
              res.data.data.map((data) => {
                return {
                  label: data.taxName,
                  value: data.id,
                  taxPercentage: data.taxPercentage,
                  taxId: data.id,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTax();
  }, [props.taxRender]);

  function handleChanged(e) {
    if (e.label) {
      console.log(e);
      setData({
        ...data,
        taxId: e.taxId,
      });
    } else setData({ ...data, [e.target.name]: e.target.value });
  }

  //-------------- GET CATEGORY ------------------
  useEffect(() => {
    api.get("/admin/product/getCategory").then((res) => {
      if (Array.isArray(res.data.data))
        setCateList(
          res.data.data.map((data) => {
            return {
              label: data.categoryName,
              value: data.id,
              id: data.id,
              tax: data.taxPercentage,
            };
          })
        );
    });
  }, []);

  //------------- SPEC --------------------
  useEffect(() => {
    if (render) {
      setRender(false);
      api
        .post("/admin/product/title", { categoryId: data.categoryId })
        .then((res) => {
          if (res.data.data == "No Details Found") return setValueList([]);
          else {
            setValueList(
              res.data.data.map((datas, i) => {
                JSON.parse(datas.productTitle).map(
                  (info, i) =>
                    (data.productSpecification[i] = {
                      productTitle: info,
                      productAnswer: "",
                    })
                );
                return {
                  productTitle: datas.productTitle,
                  categoryId: datas.categoryId,
                };
              })
            );
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [render]);

  function setTags(e) {
    setData({ ...data, tags: e });
  }

  function handleChange(e) {
    if (e.label) {
      setData({
        ...data,
        categoryName: e.label,
        categoryId: e.id,
        tax: e.tax,
        age: e.age,
      });
      setRender(!render);
    } else setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleImageChange(e) {
    if (e.size > 3 * 1024 * 1024) {
      notify("Image size should be less than 3MB", "danger");
    } else {
      setData({ ...data, image: e });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const final = {
      ...data,
      productSpecification: JSON.stringify(data.productSpecification),
    };

    const formdata = new FormData();
    Object.entries(final).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api
      .post("/admin/product/addProduct", formdata)
      .then((res) => {
        notify(res.data.data, "success");
        setSubmitRender(!submitRender);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }

  const [editorData, setEditorData] = useState("description");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
  };

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Product</h1>
              </div>
            </div>

            {/* Add Product */}
            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Product</h2>
                </header>

                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">Category Name</Label>
                          <Select
                            name="cateName"
                            className="select2"
                            options={catelist}
                            styles={customStyles}
                            onChange={handleChange}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleEmail7">Product Name</Label>
                          <Input
                            type="text"
                            name="productName"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Age : </Label>
                          <span style={{color:"red"}}> Add Max Age</span>
                          <Input
                            type="text"
                            name="age"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                          />
                        </FormGroup> */}
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">Tax Name</Label>
                          <Select
                            name="taxName"
                            className="select2"
                            options={taxlist}
                            styles={customStyles}
                            onChange={handleChanged}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Tags{" "}
                            <span
                              style={{ color: "#f8c113", fontWeight: "bold" }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                onChange={setTags}
                                onlyUnique={true}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleFile">Product Image</Label>
                          <br />
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {" "}
                            Dimensions : 480 x 480
                          </span>{" "}
                          <br />
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {" "}
                            Size: Max 3mb
                          </span>
                          <br />
                          <Cropper
                            imageStore={handleImageChange}
                            aspectRatio={1 / 1}
                            reset={submitRender}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Product Description
                          </Label>
                          <Input
                            type="textarea"
                            onChange={handleChange}
                            name="productDescription"
                            id="exampleText"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="exampleText">Product Benefits</Label>
                          <ReactQuill
                            modules={modules}
                            formats={formats}
                            theme="snow"
                            value={data.productBenefits}
                            onChange={(value) => {
                              let event = {
                                target: {
                                  name: "productBenefits",
                                  value: value,
                                },
                              };
                              handleChanges(event);
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Additional Information{" "}
                          </Label>
                          {/* <Input
                            type="textarea"
                            onChange={handleChange}
                            name="moreInfo"
                          /> */}

                          <ReactQuill
                            modules={modules}
                            formats={formats}
                            theme="snow"
                            value={data.moreInfo}
                            onChange={(value) => {
                              let event = {
                                target: {
                                  name: "moreInfo",
                                  value: value,
                                },
                              };
                              handleChanges(event);
                            }}
                          />
                        </FormGroup>

                        {/* <FormGroup>
                          <Label htmlFor="exampleText">
                            Product Formula
                          </Label>
                          <Select
                            name="productFormula"
                            className="select2"
                            options={productFormula}
                            styles={customStyles}
                            onChange={handleChanges}
                          />
                        </FormGroup> */}
                        {/* <Label htmlFor="exampleText">
                          Is Customize?
                          </Label>
                        <FormGroup check>
          <Label check>
            <Input type="radio" name="customize" value="yes" style={{marginTop:"-10px",marginBottom:"10px"}} onChange={handle}/> Yes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="customize" value="no" style={{marginTop:"-10px"}} onChange={handle} /> No
          </Label>
        </FormGroup>
       */}

                        {/*---------------------- TECH SPEC--------------------------------------------  */}
                        {valueList.map((info, index) =>
                          JSON.parse(info.productTitle).length > 0 ? (
                            <header className="panel_header">
                              <h2
                                className="title float-left"
                                style={{ paddingLeft: "0px" }}
                              >
                                Add Technical specifications
                              </h2>
                            </header>
                          ) : (
                            ""
                          )
                        )}

                        <div className="row">
                          {valueList.map((info, index) =>
                            JSON.parse(info.productTitle).map((datas, i) => (
                              <div className="col-lg-6">
                                <FormGroup>
                                  <Label
                                    htmlFor="exampleText"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {datas}
                                  </Label>
                                  <Input
                                    type="text"
                                    name="specificationValue"
                                    onChange={(e) => {
                                      // console.log(data.productSpecification)
                                      data.productSpecification[
                                        i
                                      ].productAnswer = e.target.value;
                                      // setRender(!renderScreen);
                                    }}
                                  />
                                </FormGroup>
                              </div>
                            ))
                          )}
                        </div>

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#257554",
                              color: "#fff",
                            }}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div></div>

            <ProductList render={submitRender} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddProduct;
