import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import Cropper from "../Cropper";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { customStyles } from "../../assets/css/CustomStyles";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddProduct = (props) => {
  // quill helpers start
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      ["clean"],
    ],
  };
  // quill helpers end

  const notiRef = useRef();
  const params = useParams();
  const productId = params.id;
  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function handleChanged(e) {
    if (e.label) {
      console.log(e);
      setData({
        ...data,
        taxId: e.taxId,
      });
    } else setData({ ...data, [e.target.name]: e.target.value });
  }
  const [data, setData] = useState({
    categoryId: "",
    categoryName: "",
    tax: "",
    productName: "",
    age: "",
    productFormula: "",
    productDescription: "",
    customize: "",
    moreInfo: "",
    productBenefits: "",
    productSpecification: [],
    tags: [],
  });

  const [moreInfo, setMoreInfo] = useState("");
  const [productBenefits, setProductBenefits] = useState("");
  //
  const [catelist, setCateList] = useState();
  const [tag, setTag] = useState([]);
  const [taxlist, setTaxlist] = useState();
  const [valueList, setValueList] = useState();
  const [render, setRender] = useState(false);
  const [renderScreen, setRenderScreen] = useState(false);
  const [submitRender, setSubmitRender] = useState(false);
  const [convertedImage, setConvertedImage] = useState("");
  const [GetImage, SetGetImage] = useState("");
  const [spec, setSpec] = useState(false);
  const [loading, setLoading] = useState(true);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
    var redirect =
      type === "success"
        ? setTimeout(() => (window.location.href = "/product"), 1500)
        : "";
  }

  //-------------- GET CATEGORY AND PRODUCT------------------
  useEffect(() => {
    // ------ CATEGORY ------------
    api.get("/admin/product/getCategory").then((res) => {
      if (Array.isArray(res.data.data))
        setCateList(
          res.data.data.map((data) => {
            return {
              label: data.categoryName,
              value: data.id,
              id: data.id,
              tax: data.taxPercentage,
            };
          })
        );
    });

    // ----- PRODUCT ----------
    api.get("/admin/product/getProduct/" + productId).then((res) => {
      // console.log("prod", res.data.data);
      // console.log(JSON.parse(res.data.data.tags).split(","));
      setTag(JSON.parse(res.data.data.tags).split(","));
      // console.log('tag', tag);
      // setData({...data, ...res.data.data});
      SetGetImage(res.data.data.productImage);
      try {
        setData({
          ...data,
          ...res.data.data,
          tags: JSON.parse(res.data.data.tags).split(","),
        });
        setMoreInfo(res.data.data.moreInfo);
        setProductBenefits(res.data.data.productBenefits);
      } catch {}
      setSpec(true);
    });
  }, []);
  // const productFormula = [
  //   { value: "1", label: "Flat & Fitted Bedsheets", id: "productFormula" },
  //   { value: "2", label: "Mattress Protector", id: "productFormula" },
  //   { value: "3", label: "Pillow Protector", id: "productFormula" },
  //   { value: "4", label: "Mattress Comforter", id: "productFormula" },
  //   { value: "5", label: "Dohar", id: "productFormula" },

  // ];

  // Get Tax
  useEffect(() => {
    const getTax = () => {
      api
        .post("/admin/shop/tax")
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data != "No Taxes found") {
            setTaxlist(
              res.data.data.map((data) => {
                return {
                  label: data.taxName,
                  value: data.id,
                  taxPercentage: data.taxPercentage,
                  taxId: data.id,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTax();
  }, [props.taxRender]);

  //------------- SPEC --------------------
  useEffect(() => {
    if (spec) {
      setLoading(!loading);
      api
        .post("/admin/product/specification", { productId: productId })
        .then((res) => {
          console.log(data);
          if (Array.isArray(res.data.data))
            setValueList(
              res.data.data.map((datas, i) => {
                console.log(
                  "prodSpec:",
                  JSON.parse(datas.productSpecification)
                );

                return JSON.parse(datas.productSpecification).map((info, i) => {
                  // console.log("parse", info.productTitle, info.productAnswer);
                  // console.log("parse2", datas.productSpecification);
                  return (data.productSpecification[i] = {
                    productTitle: info.productTitle,
                    productAnswer: info.productAnswer,
                  });
                });
              })
            );
        });
    }
  }, [spec]);

  useEffect(() => {
    if (convertedImage) SetGetImage(URL.createObjectURL(convertedImage));
  }, [convertedImage]);

  function setTags(e) {
    console.log(e);
    setData({ ...data, tags: e });
  }

  function handleChange(e) {
    // if (e.label) {
    //   console.log(data);
    //   setData({
    //     ...data,
    //     categoryName: e.label,
    //     categoryId: e.id,
    //     tax: e.tax,
    //     age: e.age,
    //   });
    //   setRender(!render);
    // } else

    setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleImageChange(e) {
    // console.log(e);
    setData({ ...data, image: e });
  }
  function handleChanges(e) {
    if (e.label) {
      console.log("files", e);
      setData({ ...data, productFormula: e.value });
      // e.id == "prodcate"
      //   ? setData({ ...data, productOrCategoryId: e.finalId })
      //   : setData({ ...data, bannerType: e.value });
    }
    // setData({...data, bannerType:e.value })
    else setData({ ...data, [e.target.name]: e.target.value });
  }
  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const final = {
      categoryName: data.categoryName,
      categoryId: data.categoryId,
      productName: data.productName,
      age: data.age,
      image: convertedImage,
      productSpecification: JSON.stringify(data.productSpecification),
      productBenefits: productBenefits,
      moreInfo: moreInfo,
      tags: data.tags,
      productDescription: data.productDescription,
      productId,
    };
    console.log(final);
    const formdata = new FormData();
    Object.entries(final).map((data) => {
      formdata.append(data[0], data[1]);
    });
    console.log("finall", final);
    api
      .post("/admin/product/updateProduct", formdata)
      .then((res) => {
        console.log(res.data.data);
        notify(res.data.data, "success");
        setData({ productDescription: "", tags: [], moreInfo: "" });
        setSubmitRender(!submitRender);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkArray(data) {
    // console.log('checkArray',data[0]);
    if (Array.isArray(data)) return data;
    else return [];
  }
  console.log("setData", data);
  console.log("cateName", loading);
  console.log("value", data);

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      {loading ? (
        " "
      ) : (
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Product</h1>
                </div>
              </div>

              <div className="col-12">
                <section className="box ">
                  <header className="panel_header">
                    <h2 className="title float-left">Update Product</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <Label htmlFor="exampleSelect3">
                              Category Name
                            </Label>
                            <Select
                              name="cateName"
                              defaultValue={{ label: data.categoryName }}
                              className="select2"
                              options={catelist}
                              styles={customStyles}
                              onChange={handleChange}
                              key={spec}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleEmail7">Product Name</Label>
                            <Input
                              type="text"
                              value={data.productName}
                              name="productName"
                              id="exampleEmail7"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleText">
                              Tags{" "}
                              <span
                                style={{ color: "#ffb74d", fontWeight: "bold" }}
                              >
                                (Keywords used for searching products in
                                website)
                              </span>
                            </Label>
                            <div className="form-group">
                              <div className="controls">
                                <TagsInput
                                  value={data.tags}
                                  name="tags"
                                  onChange={setTags}
                                  onlyUnique={true}
                                />
                              </div>
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleFile">Product Image</Label>
                            {/* <br /> */}
                            <br />
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              {" "}
                              Dimensions : 480 x 480
                            </span>{" "}
                            <br />
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              {" "}
                              Size: Max 3mb
                            </span>
                            <br />
                            <img
                              src={process.env.REACT_APP_BASEDIR + GetImage}
                              width={130}
                              style={{ marginBottom: "10px" }}
                              height={130}
                              alt="img"
                            />
                            <Cropper
                              imageStore={setConvertedImage}
                              image={convertedImage}
                              aspectRatio={1 / 1}
                              reset={submitRender}
                            />
                          </FormGroup>
                          {/* <Label htmlFor="exampleText">
                          Is Customize?
                          </Label>
                        <FormGroup check>
          <Label check>
            <Input type="radio" name="customize" value="yes" style={{marginTop:"-10px",marginBottom:"10px"}} onChange={handle}/> Yes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="customize" value="no" style={{marginTop:"-10px"}} onChange={handle} /> No
          </Label>
        </FormGroup> */}

                          {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Age : </Label>
                          <span style={{color:"red"}}> Add Max Age</span>
                          <Input
                            type="text"
                            name="age"
                            value={data.productAgegroup}
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                          />
                        </FormGroup> */}

                          <FormGroup>
                            <Label htmlFor="exampleSelect3">Tax Name</Label>
                            <Select
                              name="taxName"
                              className="select2"
                              options={taxlist}
                              styles={customStyles}
                              onChange={handleChanged}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleText">
                              Product Description
                            </Label>
                            <Input
                              type="textarea"
                              value={data.productDescription}
                              onChange={handleChange}
                              name="productDescription"
                              id="exampleText"
                            />

                            {/* description quill */}
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleText">
                              Product Benefits
                            </Label>

                            <ReactQuill
                              modules={modules}
                              theme="snow"
                              value={productBenefits}
                              onChange={(value) => {
                                setProductBenefits(value);
                              }}
                            />

                            {/* description quill */}
                          </FormGroup>
                          {/* <FormGroup>
                          <Label htmlFor="exampleText">
                            Product Formula
                          </Label>
                          <Select
                            name="productFormula"
                            className="select2"
                            options={productFormula}
                            // value={data.productFormula}
                           
                            onChange={handleChanges}
                          />
                        </FormGroup> */}

                          <FormGroup>
                            <Label htmlFor="exampleText">
                              More Information{" "}
                              <span
                                style={{ color: "#ffb74d", fontWeight: "bold" }}
                              >
                                {" "}
                                (Optional)
                              </span>
                            </Label>
                            {/* <Input
                              type="textarea"
                              value={data.moreInfo}
                              onChange={handleChange}
                              name="moreInfo"
                            /> */}

                            <ReactQuill
                              modules={modules}
                              theme="snow"
                              value={moreInfo}
                              onChange={(value) => {
                                setMoreInfo(value);
                              }}
                            />

                            {/* more info quill */}
                          </FormGroup>
                          {/*---------------------- TECH SPEC--------------------------------------------  */}
                          {/* {checkArray(valueList) ? (
                            <header className="panel_header">
                              <h2
                                className="title float-left"
                                style={{ paddingLeft: "0px" }}
                              >
                                Add Technical specifications
                              </h2>
                            </header>
                          ) : (
                            ""
                          )} */}

                          <div className="row">
                            {checkArray(data.productSpecification).map(
                              (info, i) => (
                                <div className="col-lg-6">
                                  <FormGroup>
                                    <Label
                                      htmlFor="exampleText"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {info.productTitle}
                                    </Label>
                                    <Input
                                      value={info.productAnswer}
                                      type="text"
                                      name="specificationValue"
                                      onChange={(e) => {
                                        console.log(
                                          "console",
                                          data.productSpecification
                                        );
                                        data.productSpecification[
                                          i
                                        ].productAnswer = e.target.value;
                                        setRenderScreen(!renderScreen);
                                      }}
                                    />
                                  </FormGroup>
                                </div>
                              )
                            )}
                          </div>

                          <FormGroup style={{ marginBottom: "0px" }}>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{
                                color: "#fff",
                                backgroundColor: "#247554",
                              }}
                            >
                              Submit
                            </button>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AddProduct;
